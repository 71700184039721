import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Navmenu from '../components/General/Nav';
import Header from '../components/General/Header';
import Footer from '../components/General/Footer';
import Country from '../components/General/Country';

import Emma from '../images/emma.png';
import Abbas from '../images/abbas.png';

const container = {
  marginTop: '5vh',
};

const h1Styles = {
  backgroundColor: '#2a2fbe',
  color: 'white',
  width: '80%',
  padding: '10px',
  boxSizing: 'border-box',
  textAlign: 'center',
  margin: '0',
};

const maxImageWidth = {
  maxWidth: '80%',
  height: 'auto',
  margin: '0',
};

const textStyles = {
  width: '80%', // Adjust this value based on your design
  textAlign: 'justify',
  marginLeft: 'auto',
  marginRight: 'auto',
};

// Add Bootstrap classes for small screens
const textStylesSmallScreen = {
  width: '80%', // Full width on small screens
  textAlign: 'justify',
};

const h2BackgroundStyles = {
  width: '80%',
  height: '20%',
  color: 'white',
  textAlign: 'center',
  backgroundColor: '#4C4E52',
  marginBottom: '2vh',
};

function Team() {
  const [isExpanded, setExpanded] = useState([false, false]);

  const toggleExpand = (index) => {
    const newExpanded = [...isExpanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  return (
    <div>
      <Header />
      <Navmenu />
      <Container className="d-flex justify-content-center" style={container}>
        <Row>
          <Col md={6} lg={6}>
            <section className="d-flex flex-column align-items-center mb-3">
              <h1 style={h1Styles}>Emma Svehla</h1>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src={Emma} alt="Emma" style={maxImageWidth} />
                <div style={h2BackgroundStyles}>
                  <h2>Senior Lawyer</h2>
                </div>
              </div>
              <div>
                {isExpanded[0] && (
                  <div style={{ ...textStyles, ...(window.innerWidth <= 768 ? textStylesSmallScreen : {}) }}>
                    <p>
                      Emma graduated from the Australian National University in 2016 with a bachelor degree in Laws and International Relations. She was admitted as a lawyer in 2016 and has 7 years of experience practicing across NSW, ACT, and Victoria. In 2018, she was tipstaff to Justice Hoeben in the NSW Supreme Court.
                    </p>
                    <p>
                      Emma has 4.5 years of experience in immigration and refugee law, having also worked overseas providing legal advice to refugees in Europe. Emma has personal experience going through the migration system so understands what it's like to apply for a visa. She is learning to speak Farsi and hopes to be fluent soon. She is experienced across all immigration matters and is an expert in complex cases.
                    </p>
                  </div>
                )}
                <div className="d-flex justify-content-center">
                  <button onClick={() => toggleExpand(0)}>
                    {isExpanded[0] ? 'Show Less' : 'Show More'}
                  </button>
                </div>
              </div>
            </section>
          </Col>
          <Col md={6} lg={6}>
            <section className="d-flex flex-column align-items-center mb-3">
              <h1 style={h1Styles}>Abbas Amiry</h1>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src={Abbas} alt="Abbas" style={maxImageWidth} />
                <div style={h2BackgroundStyles}>
                  <h2>Paralegal</h2>
                </div>
              </div>
              <div>
                {isExpanded[1] && (
                  <div style={{ ...textStyles, ...(window.innerWidth <= 768 ? textStylesSmallScreen : {}) }}>
                    <p>
                      Abbas Amiry graduated with a bachelor of Social Science majoring in International Relations and Security from Swinburne University. He has been directly involved in providing welfare support and services to Asylum Seekers and Refugees in various capacities throughout their Refugee Status Determination and resettlement process for over 17 years.
                    </p>
                    <p>
                      This includes many years of leadership roles in service provision on Christmas Island, Offshore processing Centers of Manus Island (PNG), Nauru, and Australia. He has well-developed and an enormous amount of cultural understanding and speaks fluently in multiple languages including Hazaragi, Dari, Farsi, Pashto, Urdu, and basic Arabic.
                    </p>
                  </div>
                )}
                <div className="d-flex justify-content-center">
                  <button onClick={() => toggleExpand(1)}>
                    {isExpanded[1] ? 'Show Less' : 'Show More'}
                  </button>
                </div>
              </div>
            </section>
          </Col>
        </Row>
      </Container>
      <Country />
      <Footer />
    </div>
  );
}

export default Team;
